var render = function render(){var _vm=this,_c=_vm._self._c;return _c('preview-screen',{attrs:{"title":"Mail Details","resource":"mail","id":_vm.$route.params.id},scopedSlots:_vm._u([{key:"table-parameters",fn:function(slotProps){return [_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Mailable")]),_c('td',[_vm._v(" "+_vm._s(slotProps.entry.content.mailable)+" "),(slotProps.entry.content.queued)?_c('span',{staticClass:"badge badge-secondary font-weight-light ml-2"},[_vm._v(" Queued ")]):_vm._e()])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("From")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatAddresses(slotProps.entry.content.from))+" ")])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("To")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatAddresses(slotProps.entry.content.to))+" ")])]),(slotProps.entry.replyTo)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Reply-To")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatAddresses(slotProps.entry.content.replyTo))+" ")])]):_vm._e(),(slotProps.entry.cc)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("CC")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatAddresses(slotProps.entry.content.cc))+" ")])]):_vm._e(),(slotProps.entry.bcc)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("BCC")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatAddresses(slotProps.entry.content.bcc))+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Subject")]),_c('td',[_vm._v(" "+_vm._s(slotProps.entry.content.subject)+" ")])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Download")]),_c('td',[_c('a',{attrs:{"href":_vm.Telescope.basePath +
              '/telescope-api/mail/' +
              _vm.$route.params.id +
              '/download'}},[_vm._v("Download .eml file")])])])]}},{key:"after-attributes-card",fn:function(slotProps){return _c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"card"},[_c('iframe',{attrs:{"src":_vm.Telescope.basePath +
            '/telescope-api/mail/' +
            _vm.$route.params.id +
            '/preview',"width":"100%","height":"400"}})])])}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }