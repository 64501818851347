import Vue from "vue";
import axios from "axios";
import JWTDecode from "jwt-decode";

const JWTKEY = "[Danapay Inspector] JWTKey";

const storeJWTToken = token => {
  localStorage.setItem(JWTKEY, token);
};

const setRequestToken = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = "Bearer " + token;
    return;
  }
  delete axios.defaults.headers.common.Authorization;
};

const tokenIsValid = token => {
  if (!token) {
    return false;
  }
  const decoded = JWTDecode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    return false;
  }
  return true;
};

window.$store = new Vue({
  data: () => ({ hasValidAuthToken: false }),
  created() {
    const token = localStorage.getItem(JWTKEY);

    if (tokenIsValid(token)) {
      return this.setToken(token);
    }
    // remove the token if it exists
    this.removeJWTToken();
  },
  methods: {
    setToken(token) {
      if (!token) {
        throw new Error("Attempting to set empty JWT token");
      }
      setRequestToken(token);
      storeJWTToken(token);
      this.hasValidAuthToken = true;
    },
    ensureUserIsAuthorized(to, from, next) {
      if (to.name === "auth.login") {
        return this.hasValidAuthToken ? next("/dashboard") : next();
      }
      if (this.hasValidAuthToken) {
        return next();
      }
      return next({ name: "auth.login" });
    },

    removeJWTToken() {
      this.hasValidAuthToken = false;
      localStorage.removeItem(JWTKEY);
      setRequestToken(null);
    },
  },
});
