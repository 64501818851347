var render = function render(){var _vm=this,_c=_vm._self._c;return _c('preview-screen',{attrs:{"title":"Batch Details","resource":"batches","id":_vm.$route.params.id,"entry-point":"true"},scopedSlots:_vm._u([{key:"table-parameters",fn:function(slotProps){return [_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Status")]),_c('td',[(
            slotProps.entry.content.failedJobs > 0 &&
              slotProps.entry.content.progress < 100
          )?_c('small',{staticClass:"badge badge-danger badge-sm"},[_vm._v(" Failures ")]):_vm._e(),(slotProps.entry.content.progress == 100)?_c('small',{staticClass:"badge badge-success badge-sm"},[_vm._v(" Finished ")]):_vm._e(),(
            slotProps.entry.content.totalJobs == 0 ||
              (slotProps.entry.content.pendingJobs > 0 &&
                !slotProps.entry.content.failedJobs)
          )?_c('small',{staticClass:"badge badge-secondary badge-sm"},[_vm._v(" Pending ")]):_vm._e()])]),(slotProps.entry.content.cancelledAt)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Cancelled At")]),_c('td',[_vm._v(" "+_vm._s(_vm.localTime(slotProps.entry.content.cancelledAt))+" ("+_vm._s(_vm.timeAgo(slotProps.entry.content.cancelledAt))+") ")])]):_vm._e(),(slotProps.entry.content.finishedAt)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Finished At")]),_c('td',[_vm._v(" "+_vm._s(_vm.localTime(slotProps.entry.content.finishedAt))+" ("+_vm._s(_vm.timeAgo(slotProps.entry.content.finishedAt))+") ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Batch")]),_c('td',[_vm._v(" "+_vm._s(slotProps.entry.content.name || slotProps.entry.content.id)+" ")])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Connection")]),_c('td',[_vm._v(" "+_vm._s(slotProps.entry.content.connection)+" ")])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Queue")]),_c('td',[_vm._v(" "+_vm._s(slotProps.entry.content.queue)+" ")])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Size")]),_c('td',[_c('router-link',{staticClass:"control-action",attrs:{"to":{
            name: 'jobs',
            query: { family_hash: slotProps.entry.family_hash },
          }}},[_vm._v(" "+_vm._s(slotProps.entry.content.totalJobs)+" Jobs ")])],1)]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Pending")]),_c('td',[_vm._v(" "+_vm._s(slotProps.entry.content.pendingJobs)+" ")])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Progress")]),_c('td',[_vm._v(_vm._s(slotProps.entry.content.progress)+"%")])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }