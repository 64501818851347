export default [
  { path: "", redirect: "/telescope/requests" },

  {
    path: "mail/:id",
    name: "telescope.mail-preview",
    component: require("./screens/mail/preview").default,
  },

  {
    path: "mail",
    name: "telescope.mail",
    component: require("./screens/mail/index").default,
  },

  {
    path: "exceptions/:id",
    name: "telescope.exception-preview",
    component: require("./screens/exceptions/preview").default,
  },

  {
    path: "exceptions",
    name: "telescope.exceptions",
    component: require("./screens/exceptions/index").default,
  },

  {
    path: "dumps",
    name: "telescope.dumps",
    component: require("./screens/dumps/index").default,
  },

  {
    path: "logs/:id",
    name: "telescope.log-preview",
    component: require("./screens/logs/preview").default,
  },

  {
    path: "logs",
    name: "telescope.logs",
    component: require("./screens/logs/index").default,
  },

  {
    path: "notifications/:id",
    name: "telescope.notification-preview",
    component: require("./screens/notifications/preview").default,
  },

  {
    path: "notifications",
    name: "telescope.notifications",
    component: require("./screens/notifications/index").default,
  },

  {
    path: "jobs/:id",
    name: "telescope.job-preview",
    component: require("./screens/jobs/preview").default,
  },

  {
    path: "jobs",
    name: "telescope.jobs",
    component: require("./screens/jobs/index").default,
  },

  {
    path: "batches/:id",
    name: "telescope.batch-preview",
    component: require("./screens/batches/preview").default,
  },

  {
    path: "batches",
    name: "telescope.batches",
    component: require("./screens/batches/index").default,
  },

  {
    path: "events/:id",
    name: "telescope.event-preview",
    component: require("./screens/events/preview").default,
  },

  {
    path: "events",
    name: "telescope.events",
    component: require("./screens/events/index").default,
  },

  {
    path: "cache/:id",
    name: "telescope.cache-preview",
    component: require("./screens/cache/preview").default,
  },

  {
    path: "cache",
    name: "telescope.cache",
    component: require("./screens/cache/index").default,
  },

  {
    path: "queries/:id",
    name: "telescope.query-preview",
    component: require("./screens/queries/preview").default,
  },

  {
    path: "queries",
    name: "telescope.queries",
    component: require("./screens/queries/index").default,
  },

  {
    path: "models/:id",
    name: "telescope.model-preview",
    component: require("./screens/models/preview").default,
  },

  {
    path: "models",
    name: "telescope.models",
    component: require("./screens/models/index").default,
  },

  {
    path: "requests/:id",
    name: "telescope.request-preview",
    component: require("./screens/requests/preview").default,
  },

  {
    path: "requests",
    name: "telescope.requests",
    component: require("./screens/requests/index").default,
  },

  {
    path: "commands/:id",
    name: "telescope.command-preview",
    component: require("./screens/commands/preview").default,
  },

  {
    path: "commands",
    name: "telescope.commands",
    component: require("./screens/commands/index").default,
  },

  {
    path: "schedule/:id",
    name: "telescope.schedule-preview",
    component: require("./screens/schedule/preview").default,
  },

  {
    path: "schedule",
    name: "telescope.schedule",
    component: require("./screens/schedule/index").default,
  },

  {
    path: "redis/:id",
    name: "telescope.redis-preview",
    component: require("./screens/redis/preview").default,
  },

  {
    path: "redis",
    name: "telescope.redis",
    component: require("./screens/redis/index").default,
  },

  {
    path: "monitored-tags",
    name: "telescope.monitored-tags",
    component: require("./screens/monitoring/index").default,
  },

  {
    path: "gates/:id",
    name: "telescope.gate-preview",
    component: require("./screens/gates/preview").default,
  },

  {
    path: "gates",
    name: "telescope.gates",
    component: require("./screens/gates/index").default,
  },

  {
    path: "views/:id",
    name: "telescope.view-preview",
    component: require("./screens/views/preview").default,
  },

  {
    path: "views",
    name: "telescope.views",
    component: require("./screens/views/index").default,
  },
];
