import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./bootstrap";
import "./store/global";
import Base from "./base";

import "./components/global";

Vue.config.productionTip = false;

Vue.mixin(Base);

new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      alert: {
        type: null,
        autoClose: 0,
        message: "",
        confirmationProceed: null,
        confirmationCancel: null,
      },
      autoLoadsNewEntries: true,
      recording: true,
    };
  },
  methods: {
    autoLoadNewEntries() {
      if (!this.autoLoadsNewEntries) {
        this.autoLoadsNewEntries = true;
        localStorage.autoLoadsNewEntries = 1;
      } else {
        this.autoLoadsNewEntries = false;
        localStorage.autoLoadsNewEntries = 0;
      }
    },

    toggleRecording() {},
  },
}).$mount("#app");
