var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('router-link',{attrs:{"title":_vm.job.name,"to":{
        name:
          _vm.$parent.type != 'failed'
            ? 'completed-jobs-preview'
            : 'failed-jobs-preview',
        params: { jobId: _vm.job.id },
      }}},[_vm._v(" "+_vm._s(_vm.jobBaseName(_vm.job.name))+" ")]),(_vm.delayed && (_vm.job.status == 'reserved' || _vm.job.status == 'pending'))?_c('small',{directives:[{name:"tooltip",rawName:"v-tooltip:top",value:(`Delayed for ${_vm.delayed}`),expression:"`Delayed for ${delayed}`",arg:"top"}],staticClass:"badge badge-secondary badge-sm"},[_vm._v(" Delayed ")]):_vm._e(),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" Queue: "+_vm._s(_vm.job.queue)+" "),(_vm.job.payload.tags.length)?_c('span',[_vm._v(" | Tags: "+_vm._s(_vm.job.payload.tags && _vm.job.payload.tags.length ? _vm.job.payload.tags.slice(0, 3).join(", ") : "")),(_vm.job.payload.tags.length > 3)?_c('span',[_vm._v(" ("+_vm._s(_vm.job.payload.tags.length - 3)+" more)")]):_vm._e()]):_vm._e()])],1),_c('td',{staticClass:"table-fit"},[_vm._v(" "+_vm._s(_vm.readableTimestamp(_vm.job.payload.pushedAt))+" ")]),(_vm.$parent.type == 'jobs')?_c('td',{staticClass:"table-fit"},[_vm._v(" "+_vm._s(_vm.job.completed_at ? _vm.readableTimestamp(_vm.job.completed_at) : "-")+" ")]):_vm._e(),(_vm.$parent.type == 'jobs')?_c('td',{staticClass:"table-fit"},[_c('span',[_vm._v(_vm._s(_vm.job.completed_at ? (_vm.job.completed_at - _vm.job.reserved_at).toFixed(2) + "s" : "-"))])]):_vm._e(),(_vm.$parent.type == 'failed')?_c('td',{staticClass:"table-fit"},[_vm._v(" "+_vm._s(_vm.readableTimestamp(_vm.job.failed_at))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }