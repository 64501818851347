var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex align-items-center justify-content-between"},[(!_vm.ready)?_c('h5',[_vm._v("Job Preview")]):_vm._e(),(_vm.ready)?_c('h5',[_vm._v(_vm._s(_vm.job.name))]):_vm._e(),_c('a',{attrs:{"data-toggle":"collapse","href":"#collapseDetails","role":"button"}},[_vm._v(" Collapse ")])]),(!_vm.ready)?_c('div',{staticClass:"d-flex align-items-center justify-content-center card-bg-secondary p-5 bottom-radius"},[_c('svg',{staticClass:"icon spin mr-2 fill-text-color",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M12 10a2 2 0 0 1-3.41 1.41A2 2 0 0 1 10 8V0a9.97 9.97 0 0 1 10 10h-8zm7.9 1.41A10 10 0 1 1 8.59.1v2.03a8 8 0 1 0 9.29 9.29h2.02zm-4.07 0a6 6 0 1 1-7.25-7.25v2.1a3.99 3.99 0 0 0-1.4 6.57 4 4 0 0 0 6.56-1.42h2.1z"}})]),_c('span',[_vm._v("Loading...")])]):_vm._e(),(_vm.ready)?_c('div',{staticClass:"card-body card-bg-secondary collapse show",attrs:{"id":"collapseDetails"}},[_c('div',{staticClass:"row mb-2"},[_vm._m(0),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.job.id))])]),_c('div',{staticClass:"row mb-2"},[_vm._m(1),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.job.queue))])]),_c('div',{staticClass:"row mb-2"},[_vm._m(2),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.readableTimestamp(_vm.job.payload.pushedAt)))])]),(_vm.prettyPrintJob(_vm.job.payload.data).batchId)?_c('div',{staticClass:"row mb-2"},[_vm._m(3),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
              name: 'batches-preview',
              params: { batchId: _vm.prettyPrintJob(_vm.job.payload.data).batchId },
            }}},[_vm._v(" "+_vm._s(_vm.prettyPrintJob(_vm.job.payload.data).batchId)+" ")])],1)]):_vm._e(),(_vm.delayed)?_c('div',{staticClass:"row mb-2"},[_vm._m(4),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.delayed))])]):_vm._e(),_c('div',{staticClass:"row"},[_vm._m(5),(_vm.job.completed_at)?_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.readableTimestamp(_vm.job.completed_at))+" ")]):_c('div',{staticClass:"col"},[_vm._v("-")])])]):_vm._e()]),(_vm.ready)?_c('div',{staticClass:"card mt-4"},[_vm._m(6),_c('div',{staticClass:"card-body code-bg text-white collapse show",attrs:{"id":"collapseData"}},[_c('vue-json-pretty',{attrs:{"data":_vm.prettyPrintJob(_vm.job.payload.data)}})],1)]):_vm._e(),(_vm.ready && _vm.job.payload.tags.length)?_c('div',{staticClass:"card mt-4"},[_vm._m(7),_c('div',{staticClass:"card-body code-bg text-white collapse show",attrs:{"id":"collapseTags"}},[_c('vue-json-pretty',{attrs:{"data":_vm.job.payload.tags}})],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Queue")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Pushed At")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Batch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Delayed Until")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Completed At")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex align-items-center justify-content-between"},[_c('h5',[_vm._v("Data")]),_c('a',{attrs:{"data-toggle":"collapse","href":"#collapseData","role":"button"}},[_vm._v(" Collapse ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex align-items-center justify-content-between"},[_c('h5',[_vm._v("Tags")]),_c('a',{attrs:{"data-toggle":"collapse","href":"#collapseTags","role":"button"}},[_vm._v(" Collapse ")])])
}]

export { render, staticRenderFns }