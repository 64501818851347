import axios from "axios";
import lodash from "lodash";
import Vue from "vue";
window._ = lodash;
require("bootstrap");

const {
  VUE_APP_DANAPAY_API_SECRET: secret,
  VUE_APP_API_URL: baseURL,
} = process.env;

axios.defaults.headers.common["X-API-SECRET"] = secret;

axios.defaults.baseURL = baseURL;

Vue.prototype.$http = axios;

window.Popper = require("popper.js").default;

window.Telescope = { basePath: "telescope" };

window.Horizon = { path: "workers" };
