import { render, staticRenderFns } from "./RelatedEntries.vue?vue&type=template&id=52dcb516&scoped=true&"
import script from "./RelatedEntries.vue?vue&type=script&lang=js&"
export * from "./RelatedEntries.vue?vue&type=script&lang=js&"
import style0 from "./RelatedEntries.vue?vue&type=style&index=0&id=52dcb516&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52dcb516",
  null
  
)

export default component.exports