<template>
  <div>
    <top-header />
    <div class="container mb-5">
      <div class="row mt-4">
        <telescope-sidebar />
        <div class="col-10">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TelescopeSidebar from "@/telescope/sidebar";

export default {
  components: { TelescopeSidebar },
};
</script>
