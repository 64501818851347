var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex align-items-center justify-content-between"},[_c('h5',[_vm._v("Recent Jobs for \""+_vm._s(_vm.$route.params.tag)+"\"")])]),_c('ul',{staticClass:"nav nav-pills card-bg-secondary"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
            name: 'monitoring-jobs',
            params: { tag: _vm.$route.params.tag },
          },"href":"#"}},[_vm._v(" Recent Jobs ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
            name: 'monitoring-failed',
            params: { tag: _vm.$route.params.tag },
          },"href":"#"}},[_vm._v(" Failed Jobs ")])],1)]),_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }