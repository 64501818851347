var render = function render(){var _vm=this,_c=_vm._self._c;return _c('index-screen',{attrs:{"title":"Batches","resource":"batches","hide-search":"true"},scopedSlots:_vm._u([{key:"row",fn:function(slotProps){return [_c('td',[_c('span',{attrs:{"title":slotProps.entry.content.name}},[_vm._v(_vm._s(_vm.truncate( slotProps.entry.content.name || slotProps.entry.content.id, 68, )))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" Connection: "+_vm._s(slotProps.entry.content.connection)+" | Queue: "+_vm._s(slotProps.entry.content.queue)+" ")])]),_c('td',[(
          slotProps.entry.content.failedJobs > 0 &&
            slotProps.entry.content.progress < 100
        )?_c('small',{staticClass:"badge badge-danger badge-sm"},[_vm._v(" Failures ")]):_vm._e(),(slotProps.entry.content.progress == 100)?_c('small',{staticClass:"badge badge-success badge-sm"},[_vm._v(" Finished ")]):_vm._e(),(
          slotProps.entry.content.totalJobs == 0 ||
            (slotProps.entry.content.pendingJobs > 0 &&
              !slotProps.entry.content.failedJobs)
        )?_c('small',{staticClass:"badge badge-secondary badge-sm"},[_vm._v(" Pending ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(slotProps.entry.content.totalJobs))]),_c('td',[_vm._v(_vm._s(slotProps.entry.content.progress)+"%")]),_c('td',{staticClass:"table-fit",attrs:{"data-timeago":slotProps.entry.created_at,"title":slotProps.entry.created_at}},[_vm._v(" "+_vm._s(_vm.timeAgo(slotProps.entry.created_at))+" ")]),_c('td',{staticClass:"table-fit"},[_c('router-link',{staticClass:"control-action",attrs:{"to":{ name: 'batch-preview', params: { id: slotProps.entry.id } }}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 22 16"}},[_c('path',{attrs:{"d":"M16.56 13.66a8 8 0 0 1-11.32 0L.3 8.7a1 1 0 0 1 0-1.42l4.95-4.95a8 8 0 0 1 11.32 0l4.95 4.95a1 1 0 0 1 0 1.42l-4.95 4.95-.01.01zm-9.9-1.42a6 6 0 0 0 8.48 0L19.38 8l-4.24-4.24a6 6 0 0 0-8.48 0L2.4 8l4.25 4.24h.01zM10.9 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"}})])])],1)]}}])},[_c('tr',{attrs:{"slot":"table-header"},slot:"table-header"},[_c('th',{attrs:{"scope":"col"}},[_vm._v("Batch")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Size")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Completion")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Happened")]),_c('th',{attrs:{"scope":"col"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }