<template>
  <div>
    <div class="card col-md-4 mx-auto mt-5">
      <h1 class="card-title text-center my-3">Danapay Inspector</h1>
      <hr />
      <div class="card-body">
        <div v-if="error" class="alert alert-danger" v-text="error"></div>
        <form @submit.prevent="login">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              v-model="form.email"
              required
            />
          </div>

          <div class="form-group">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="form.password"
              required
            />
          </div>

          <div class="form-group">
            <button
              class="btn btn-primary btn-block btn-bg"
              :disabled="isSubmitting"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "@/sass/app.scss";

.card {
  border-radius: 0;
}
h1 {
  font-weight: 900;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
<script>
import axios from "axios";
import _ from "lodash";
export default {
  data: () => ({
    form: { email: "", password: "" },
    errors: null,
    error: null,
    isSubmitting: false,
  }),
  methods: {
    login() {
      this.error = null;
      this.isSubmitting = true;
      axios
        .post("api/v1/auth/byEmail", this.form)
        .then(({ data }) => {
          this.isSubmitting = false;
          if (!data.user.is_admin) {
            this.error = "Unauthorized !!";
            return;
          }
          window.$store.setToken(data.access_token);
          this.$router.push("/telescope/requests");
        })
        .catch(error => {
          this.isSubmitting = false;
          this.error = _.get(
            error,
            "response.data.message",
            "Something went wrong",
          );
        });
    },
  },
};
</script>
