var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex align-items-center justify-content-between"},[_c('h5',[_vm._v(_vm._s(this.title))])]),(!_vm.ready)?_c('div',{staticClass:"d-flex align-items-center justify-content-center card-bg-secondary p-5 bottom-radius"},[_c('svg',{staticClass:"icon spin mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M12 10a2 2 0 0 1-3.41 1.41A2 2 0 0 1 10 8V0a9.97 9.97 0 0 1 10 10h-8zm7.9 1.41A10 10 0 1 1 8.59.1v2.03a8 8 0 1 0 9.29 9.29h2.02zm-4.07 0a6 6 0 1 1-7.25-7.25v2.1a3.99 3.99 0 0 0-1.4 6.57 4 4 0 0 0 6.56-1.42h2.1z"}})]),_c('span',[_vm._v("Fetching...")])]):_vm._e(),(_vm.ready && !_vm.entry)?_c('div',{staticClass:"d-flex align-items-center justify-content-center card-bg-secondary p-5 bottom-radius"},[_c('span',[_vm._v("No entry found.")])]):_vm._e(),_c('div',{staticClass:"table-responsive"},[(_vm.ready && _vm.entry)?_c('table',{staticClass:"table mb-0 card-bg-secondary table-borderless"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Time")]),_c('td',[_vm._v(" "+_vm._s(_vm.localTime(_vm.entry.created_at))+" ("+_vm._s(_vm.timeAgo(_vm.entry.created_at))+") ")])]),_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Hostname")]),_c('td',[_vm._v(" "+_vm._s(_vm.entry.content.hostname)+" ")])]),_vm._t("table-parameters",null,{"entry":_vm.entry}),(!_vm.entryPoint && _vm.job)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Job")]),_c('td',[_c('router-link',{staticClass:"control-action",attrs:{"to":{
                  name: 'telescope.job-preview',
                  params: { id: _vm.job.id },
                }}},[_vm._v(" View Job ")])],1)]):_vm._e(),(!_vm.entryPoint && _vm.request)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Request")]),_c('td',[_c('router-link',{staticClass:"control-action",attrs:{"to":{
                  name: 'telescope.request-preview',
                  params: { id: _vm.request.id },
                }}},[_vm._v(" View Request ")])],1)]):_vm._e(),(!_vm.entryPoint && _vm.command)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Command")]),_c('td',[_c('router-link',{staticClass:"control-action",attrs:{"to":{
                  name: 'telescope.command-preview',
                  params: { id: _vm.command.id },
                }}},[_vm._v(" View Command ")])],1)]):_vm._e(),(_vm.entry.tags.length)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Tags")]),_c('td',_vm._l((_vm.entry.tags),function(tag){return _c('router-link',{key:tag,staticClass:"badge badge-info mr-1 font-weight-light",attrs:{"to":{ name: _vm.resource, query: { tag: tag } }}},[_vm._v(" "+_vm._s(tag)+" ")])}),1)]):_vm._e()],2)]):_vm._e()]),(_vm.ready && _vm.entry)?_vm._t("below-table",null,{"entry":_vm.entry}):_vm._e()],2),(_vm.ready && _vm.entry && _vm.entry.content.user && _vm.entry.content.user.id)?_c('div',{staticClass:"card mt-5"},[_vm._m(0),_c('table',{staticClass:"table mb-0 card-bg-secondary table-borderless"},[_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("ID")]),_c('td',[_vm._v(" "+_vm._s(_vm.entry.content.user.id)+" ")])]),(_vm.entry.content.user.name)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold align-middle"},[_vm._v("Name")]),_c('td',{staticClass:"align-middle"},[(_vm.entry.content.user.avatar)?_c('img',{staticClass:"mr-2 rounded-circle",attrs:{"src":_vm.entry.content.user.avatar,"alt":_vm.entry.content.user.name,"height":"40","width":"40"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.entry.content.user.name)+" ")])]):_vm._e(),(_vm.entry.content.user.email)?_c('tr',[_c('td',{staticClass:"table-fit font-weight-bold"},[_vm._v("Email Address")]),_c('td',[_vm._v(" "+_vm._s(_vm.entry.content.user.email)+" ")])]):_vm._e()])]):_vm._e(),(_vm.ready && _vm.entry)?_vm._t("after-attributes-card",null,{"entry":_vm.entry}):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex align-items-center justify-content-between"},[_c('h5',[_vm._v("Authenticated User")])])
}]

export { render, staticRenderFns }