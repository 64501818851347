import Vue from "vue";
import VueRouter from "vue-router";
import telescopeRoutes from "@/telescope/routes";
import horizonRoutes from "@/horizon/routes";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "auth.login",
    component: require("@/views/login").default,
  },
  {
    path: "/telescope",
    component: require("@/views/telescope").default,
    children: telescopeRoutes,
  },

  {
    path: "/",
    component: require("@/views/telescope").default,
    children: horizonRoutes,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((...args) => {
  window.$store.ensureUserIsAuthorized(...args);
});

export default router;
